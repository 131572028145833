.hero-container {
  display: flex;
  justify-content: center;
  padding: 0px 60px;
  margin-top: 76px;
  background-color: #fafafa;
  @media (max-width: 1439px) {
    margin-top: 72px;
  }
  @media (max-width: 1024px) {
    padding: 0px 32px;
    margin-top: 56px;
  }
  @media (max-width: 767px) {
    padding: 0px 16px;
  }
  @media (max-width: 479px) {
    padding: 0px 12px;
  }
  .background-img-container {
    height: 800px;
    background: #fbf8fc;
    width: 1320px;
    @media (max-width: 1439px) {
      width: 1080px;
      height: 612px;
    }
    @media (max-width: 1024px) {
      width: 704px;
      height: 428px;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 767px) {
      width: 448px;
      height: 255px;
    }
    @media (max-width: 479px) {
      width: 296px;
      height: 167px;
    }
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .get-started-container {
    display: flex;
    position: absolute;
    top: 212px;
    left: 88px;
    width: 552px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 24px;
    opacity: 0.8;
    background: var(--White, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(2px);
    @media (max-width: 1439px) {
      left: 0px;
      top: 132px;
    }
    @media (max-width: 1024px) {
      left: auto;
      right: auto;
      top: 274px;
      gap: 16px;
    }
    @media (max-width: 767px) {
      left: auto;
      right: auto;
      top: 230px;
      padding: 12px;
      gap: 12px;
      width: 424px;
    }
    @media (max-width: 479px) {
      left: auto;
      right: auto;
      top: 90%;
      padding: 12px;
      gap: 12px;
      width: 281px;
    }
    .title {
      @media (max-width: 1439px) {
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -2.8px;
      }
      @media (max-width: 767px) {
        font-size: 48px;
        line-height: 56px;
      }
      @media (max-width: 479px) {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .description {
      font-weight: 700;
      width: 78%;
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (max-width: 479px) {
        line-height: 24px;
      }
    }
    .button-container {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
      @media (max-width: 479px) {
        flex-direction: column;
        .action-button {
          width: -webkit-fill-available;
          justify-content: center;
          height: 14px;
        }
      }
      .action-button:first-child {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: var(--Green, #72c420);
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        &:hover {
          background: #599919;
        }
      }
      .action-button:last-child {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        color: #2e2e2e;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        &:hover {
          border: 1px solid var(--Black, #2e2e2e);
        }
      }
    }
  }
  .by-prepaire-logo {
    width: 114px;
    height: 46px;
    position: absolute;
    right: 60px;
    bottom: 112px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
