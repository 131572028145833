.howitworks-container {
  margin: 135px 60px 183px 60px;
  @media (max-width: 1439px) {
    margin: 135px 60px 60px 60px;
  }
  @media (max-width: 1024px) {
    margin: 80px 16px 48px 16px;
  }
}

.howitworks-title {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  img {
    width: 164px;
    height: 49px;
  }
  @media (max-width: 1024px) {
    img {
      width: 104px;
      height: 38px;
    }
    font-size: 32px;
    line-height: 40px; /* 125% */
    letter-spacing: -1.6px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.howitworks-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  @media (max-width: 1439px) {
    gap: 40px;
  }
  @media (max-width: 1024px) {
    gap: 32px;
  }
}

.howitworks-card {
  display: flex;
  max-width: 1800px;
  width: -webkit-fill-available;
  padding: 40px;
  align-items: center;
  justify-content: space-between;
  gap: 120px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;

  /* Dropcards */
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
  @media (max-width: 1439px) {
    padding: 24px;
    gap: 24px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
    padding: 0px;
  }
}

.howitworks-card-content {
  max-width: 990px;
  @media (max-width: 1024px) {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .step-title {
      padding: 0px 12px;
      display: flex;
      align-items: center;
      text-align: center;
      gap: 24px;
      align-self: stretch;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
      h2 {
        line-height: 32px; /* 133.333% */
        letter-spacing: -1.2px;
      }
    }
  }

  .step-label {
    strong {
      align-items: center;
      display: flex;
    }
    span {
      color: #72c420;
      text-align: center;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 108.333% */
      letter-spacing: -2.4px;
      margin-left: 8px;
    }
  }

  h2 {
    margin-bottom: 16px;
    @media (max-width: 1439px) {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
      letter-spacing: -1.6px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
      margin-bottom: 0px;
    }
  }

  h4 {
    color: rgba(43, 43, 43, 0.7);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    @media (max-width: 1024px) {
      padding: 0px 12px;
      line-height: 24px; /* 150% */
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      padding: 0px;
    }
    @media (max-width: 479px) {
      color: rgba(43, 43, 43, 0.7);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }

  button {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #5498ee;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
    padding: 8px 12px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    &:hover {
      background: #2f63a5;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
      transition: 0.2s;
    }
  }
}

.howitworks-first-cover-img {
  display: flex;
  width: 40%;
  padding: 16px 13px 0px 13px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f5f5f5;
  @media (max-width: 1439px) {
    width: 491px;
  }
  @media (max-width: 1024px) {
    width: -webkit-fill-available;
    padding: 24px 24px 0px 24px;
  }
  @media (max-width: 767px) {
    padding: 12px 8px 0px 8px;
  }
  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
    transition: height 0.3s ease;
    @media (max-width: 1024px) {
      height: 296px;
    }
    @media (max-width: 767px) {
      height: 228px;
    }
    @media (max-width: 479px) {
      height: 143px;
    }
  }
  transition: padding 0.3s ease, background-color 0.3s ease;
}

.blur-effect-container {
  padding: 0px;
  @media (max-width: 1024px) {
    padding: 24px 24px 0px 24px;
  }
}

.howitworks-second-cover-img {
  display: flex;
  width: 40%;
  padding: 16px 13px 0px 13px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f5f5f5;
  @media (max-width: 1439px) {
    width: 491px;
  }
  @media (max-width: 1024px) {
    width: -webkit-fill-available;
    padding: 24px 24px 0px 24px;
  }
  @media (max-width: 767px) {
    padding: 12px 8px 0px 8px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
    transition: padding 0.3s ease, background-color 0.3s ease, height 0.3s ease;
    border-radius: 5.517px;
    border: 0.69px solid var(--Prepaire-Stroke-Color, rgba(0, 0, 0, 0.1));
    background: #fff;
    box-shadow: 0px 2.759px 22.068px 0px rgba(0, 0, 0, 0.06);
    @media (max-width: 1024px) {
      height: 296px;
    }
    @media (max-width: 767px) {
      height: 228px;
    }
    @media (max-width: 479px) {
      height: 143px;
    }
  }
  transition: padding 0.3s ease, background-color 0.3s ease, height 0.3s ease;
}

@media (max-width: 1024px) {
  #step-2,
  #step-4,
  #step-6 {
    flex-direction: column-reverse;
  }
}
