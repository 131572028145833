.grantai-formattedText-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  width: 100%;
  strong {
    color: #17072a;
  }
  .h3-formatter {
    color: #17072a;
    display: inline-block;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    text-align: left;
  }
  .h1-formatter {
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
  }

  .p-formatter {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.28px;
    line-height: 24px;
  }

  .li-formatter {
    color: rgba(43, 43, 43, .7);
    gap: 10px;
    margin-bottom: 12px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.28px;
    line-height: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    &::marker {
      font-weight: bold;
    }
    a {
      color: #17072a;
      text-decoration: underline;
    }
  }

  .ol-formatter {
    margin: 4px 0;
    padding-left: 16px;
  }

  .highlighted {
    background: #72c420;
  }
  a {
    color: #17072a;
    text-decoration: underline;
  }
}
