.grantai-platform-outter-container {
  display: flex;
  justify-content: center;
  margin-top: -68px;
  @media (max-width: 1439px) {
    margin-top: -25px;
  }
  @media (max-width: 1024px) {
    margin-top: 224px;
  }
  @media (max-width: 767px) {
    margin-top: 305px;
  }
  @media (max-width: 479px) {
    margin-top: 401px;
  }
  .grantai-platform-container {
    border-radius: 16px;
    border: 12px solid #fff;
    background: #fff;

    /* Drop Shadow Cards */
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    width: 1141px;
    padding-left: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    z-index: 1;
    .video-wrapper {
      overflow: hidden;
    }
    video {
      width: 100%;
      // height: fit-content;
    }
    @media (max-width: 1439px) {
      width: 944px;
    }
    @media (max-width: 1024px) {
      width: 584px;
    }
    @media (max-width: 767px) {
      width: 447px;
    }
    @media (max-width: 479px) {
      width: 100%;
      margin-left: 16px;
      margin-right: 16px;
    }
    .platform-description {
      display: flex;
      width: 945px;
      padding-bottom: 12px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      @media (max-width: 1439px) {
        gap: 27.95px;
        margin-top: 30px;
      }
      @media (max-width: 1024px) {
        width: 100%;
        margin-top: 15px;
        gap: 17.07px;
      }
      @media (max-width: 479px) {
        margin-top: -7px;
        gap: 12px;
      }
      .title {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        label {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          img {
            width: 160px;
            height: 48px;
            @media (max-width: 767px) {
              width: 130px;
              height: 45px;
            }
            @media (max-width: 479px) {
              width: 100px;
              height: 39px;
            }
          }
        }
        @media (max-width: 767px) {
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px; /* 120% */
          letter-spacing: -2px;
          width: 220px;
          text-align: center;
        }
        @media (max-width: 479px) {
          font-size: 32px;
          width: 220px;
          text-align: center;
          line-height: 40px; /* 125% */
          letter-spacing: -1.6px;
        }
      }
      .description {
        padding: 0px 80px;
        @media (max-width: 1024px) {
          font-size: 14px;
          padding: 0px 40px;
          color: #2e2e2e;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: -0.28px;
        }
        @media (max-width: 479px) {
          padding: 0px 16px;
          color: #2e2e2e;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: -0.28px;
        }
        span {
          color: #72c420;
          font-weight: 600;
        }
      }
      .action-button-container {
        display: flex;
        justify-content: center;
        gap: 24px;
        .action-button:first-child {
          display: flex;
          padding: 12px 16px;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: var(--Green, #72c420);
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          &:hover {
            background: #599919;
          }
        }
        .action-button:last-child {
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 8px;
          color: #2e2e2e;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          &:hover {
            border: 1px solid var(--Black, #2e2e2e);
          }
          @media (max-width: 479px) {
            height: 14px;
          }
        }
      }
    }
  }
}
