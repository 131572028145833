.partners-outter-container {
  background: var(--Lightest-Grey, #fafafa);
  display: flex;
  justify-content: center;
  margin-top: 136px;
  @media (max-width: 1439px) {
    margin-top: 34px;
  }
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
  @media (max-width: 767px) {
    margin-top: 31px;
  }
  @media (max-width: 479px) {
    margin-top: 48px;
  }
  .partners-container {
    display: flex;
    padding: 80px 60px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 1600px;
    @media (max-width: 1024px) {
      padding: 40px;
    }
    @media (max-width: 767px) {
      padding: 24px 16px;
    }
    .title {
      @media (max-width: 767px) {
        font-size: 40px;
      }
      @media (max-width: 479px) {
        font-size: 32px;
      }
    }
    .description {
      text-align: center;
      @media (max-width: 767px) {
        color: rgba(0, 0, 0, 0.7);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    .partners-list-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 24px;
      justify-content: flex-start;
      width: 100%;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 767px) {
        row-gap: 12px;
        grid-template-columns: 1fr;
      }
      .partner {
        display: flex;
        height: 78px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.06);

        /* droplogos */
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
        min-width: 200px;
        img {
          width: 180px;
          height: 37.444px;
        }
      }
      .six-partner {
        img {
          width: 291px;
          height: 56px;
        }
      }
    }
    button {
      min-width: 126px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #2e2e2e;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      &:hover {
        border: 1px solid var(--Black, #2e2e2e);
      }
    }
  }
}
