.global-opportunities-outter-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: 1024px) {
    margin-top: 9px;
  }
  @media (max-width: 767px) {
    padding: 24px 16px;
    margin-top: -33px;
  }
  @media (max-width: 479px) {
    margin-top: -14px;
  }
  .global-opportunities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 80px;
    max-width: 1200px;
    width: 100%;
    padding: 60px 0px 40px 0px;
    display: flex;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    @media (max-width: 1439px) {
      gap: 64px;
      max-width: 942px;
    }
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 48px;
    }
    @media (max-width: 767px) {
      gap: 24px;
    }
    @media (max-width: 479px) {
      padding: 60px 0px 24px 0px;
    }
    .global-opportunity-background {
      display: flex;
      flex-direction: column;
      width: 92%;
      gap: 18px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      height: 290px;
      @media (max-width: 1024px) {
        height: 235px;
        width: 100%;
        max-width: 500px;
      }
      @media (max-width: 479px) {
        height: 166px;
      }
    }
    .description-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 32px;
      @media (max-width: 1024px) {
        align-items: center;
        width: 79%;
        padding: 0px 40px;
      }
      @media (max-width: 767px) {
        padding: 0px 16px;
        width: calc(100% - 32px);
        gap: 24px;
      }
      .title {
        @media (max-width: 1024px) {
          text-align: center;
        }
        @media (max-width: 767px) {
          color: #2b2b2b;
          font-family: Inter;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px; /* 120% */
          letter-spacing: -2px;
        }
        @media (max-width: 479px) {
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: -1.6px;
        }
      }
      .description {
        @media (max-width: 1024px) {
          text-align: center;
        }
        @media (max-width: 767px) {
          color: #2e2e2e;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.28px;
        }
      }
    }
  }
}
