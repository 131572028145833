.what-we-offer-outter-container {
  display: flex;
  justify-content: center;
  margin-top: 101px;
  @media (max-width: 1439px) {
    margin-top: 1px;
  }
  @media (max-width: 1024px) {
    margin-top: -6px;
  }
  .what-we-offer-container {
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    @media (max-width: 1439px) {
      padding: 40px;
    }
    @media (max-width: 767px) {
      padding: 0px 16px;
      gap: 24px;
    }
    .title {
      @media (max-width: 1024px) {
        font-size: 40px;
      }
      @media (max-width: 320px) {
        font-size: 32px;
      }
    }
    .services-outter-container {
      display: flex;
      align-items: flex-start;
      gap: 64px;
      align-self: stretch;
      flex-wrap: wrap;
      @media (max-width: 1439px) {
        gap: 40px;
      }
      @media (max-width: 1024px) {
        gap: 24px;
      }
      @media (max-width: 767px) {
        gap: 16px;
      }
      .service-container {
        display: flex;
        padding: 24px;
        width: calc((100% - 64px - 96px) / 2);
        height: 124px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 12px;
        background: var(--White, #fff);

        /* Drop Shadow Cards */
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);

        @media (max-width: 1439px) {
          width: calc((100% - 40px - 96px) / 2);
        }

        @media (max-width: 1024px) {
          width: 100%;
          height: 100px;
        }
        @media (max-width: 767px) {
          padding: 12px;
          height: 124px;
        }
        @media (max-width: 479px) {
          height: 188px;
        }
        img {
          width: 72px;
          height: 72px;
        }
        .contents {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          h3 {
            @media (max-width: 479px) {
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 125% */
              letter-spacing: -0.64px;
            }
          }
          h4 {
            line-height: 24px;
            letter-spacing: 0px;
            @media (max-width: 767px) {
              font-size: 14px;
            }
            @media (max-width: 479px) {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
