.GrantAnimation-outter-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 767px) {
    margin-top: 40px;
  }
  @media (max-width: 479px) {
    margin-top: 50px;
  }
  .GrantAnimation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    video {
      width: 100%;
      border-radius: 60px;
      box-sizing: border-box;
      border: 1px solid #fff;
      &:focus {
        outline: none;
      }
      @media (max-width: 1439px) {
        height: 511px;
      }
      @media (max-width: 767px) {
        height: 279px;
      }
      @media (max-width: 479px) {
        height: 200px;
      }
    }
    .navigation-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      align-self: stretch;
      margin-top: 44px;
      @media (max-width: 1439px) {
        margin-top: 4px;
        gap: 24px;
      }
      @media (max-width: 479px) {
        gap: 12px;
      }
      h2 {
        @media (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 479px) {
          font-size: 32px;
          line-height: 40px; /* 125% */
          letter-spacing: -1.6px;
          text-align: center;
        }
      }
      .action-button {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: var(--Green, #72c420);
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        &:hover {
          background: #599919;
        }
      }
    }
  }
}
