.waitlist-outter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 76px;
  margin-bottom: 110px;
  padding: 70px 24px 0px 24px;
  @media (max-width: 1024px) {
    padding: 24px 16px 48px 16px;
    margin-top: 59px;
    margin-bottom: 32px;
    gap: 83px;
  }
  @media (max-width: 767px) {
    gap: 85px;
    margin-bottom: 43px;
  }
  @media (max-width: 479px) {
    margin-top: 53px;
    margin-bottom: 94px;
  }
  .waitlist-container {
    border-radius: var(--12, 12px);
    border: 1px solid var(--Grey-Strokes, #e5e5e5);
    background: #fff;
    max-width: 696px;
    /* Drop Shadow Cards */
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.06);
    display: inline-flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 80%;
    h2 {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      flex-wrap: wrap;
      img {
        width: 164px;
        height: 47px;
      }
    }
    .waitlist-background {
      width: 100%;
    }
    .description {
      color: var(--black-80, rgba(28, 28, 28, 0.8));
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .tip-texts-container {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .submit-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      width: 100%;
      .input-group-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        .input-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          width: 100%;
          .input-title {
            color: var(--black-80, rgba(28, 28, 28, 0.8));
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
          input {
            display: flex;
            padding: 10px var(--12, 12px);
            justify-content: space-between;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: var(--8, 8px);
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: var(--Generic-White, #fff);
            width: -webkit-fill-available;
            color: rgba(28, 28, 28, 0.8);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            &::placeholder {
              color: #737373;

              /* Paragraph/Small/Regular */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
            &:focus {
              outline: none;
            }
          }
          .notify-on-container {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            label {
              color: #737373;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
            }
            .notify-on-items {
              display: flex;
              align-items: center;
              gap: 12px;
              .notify-item {
                color: #737373;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                display: flex;
                align-items: center;
                gap: 4px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
      .submit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--4, 4px);
        border: 1px solid #72c420;
        background: var(--highlight, #72c420);
        padding: 8px 0px;
        /* Drop Hover */
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.28px;
        width: 100%;
        &:focus {
          outline: none;
        }
        &:hover {
          background: #599919;
        }
      }
    }
    .terms-description {
      color: #737373;
      text-align: center;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 184.615% */
      width: 100%;
      text-align: center;
    }
  }
}
