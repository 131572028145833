.chat-shared-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100vh;
  position: relative;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
  .chat-shared-title {
    display: flex;
    height: 60px;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    gap: 5px;
    justify-content: center;
    width: 100%;
    label:first-child {
      overflow: hidden;
      color: #72C420;
      text-align: center;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    label:last-child {
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      color: #2E2E2E;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .chat-section {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .chat-container {
      display: flex;
      width: 818px;
      padding: 32px 48px 32px 56px;
      flex-direction: column;
      gap: 24px;
      .chat-container-title {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        label:first-child {
          color:  #2E2E2E;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }
        label:last-child {
          color:  #6D6D6D;
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .chat-container-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .chat-content-logo {
          display: flex;
          justify-content: flex-start;
          gap: 8px;
          width: 100%;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
          }
          label {
            color: #1C1C1C;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.32px;
          }
        }
      }
    }
    .separator-line {
      background: #e5e5e5;
      height: 1px;
      width: 100%;
    }
  }
  .chat-shared-login-button {
    display: inline-flex;
    height: 48px;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #72C420;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);
    width: 175px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    position: fixed;
    bottom: 20px;
  }
}