.context-bar {
  display: flex;
  flex-direction: column;
  gap: 28px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;

  /* Drop Soft */
  box-shadow: 0px 4px 4px 0px rgba(5, 21, 30, 0.04);
  position: fixed;
  width: 100%;
  z-index: 12;
  top: 0px;
  transition: top 0.2s;
  .context-bar-inner-container {
    display: flex;
    padding: 12px 60px;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    @media (max-width: 1439px) {
      padding: 12px 40px;
    }
    @media (max-width: 1024px) {
      padding: 12px 24px;
      height: fit-content;
    }
    @media (max-width: 767px) {
      padding: 12px 16px;
    }
    .logo-container {
      display: flex;
      align-items: center;
      gap: 6.4px;
      cursor: pointer;
      .logo {
        display: flex;
        height: 32px;
        width: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 2.133px;
        background: #72c420;
      }
    }
    .control-btn-group-container {
      display: flex;
      align-items: center;
      gap: 32px;
      @media (max-width: 1024px) {
        display: none;
      }
      .control-btn {
        display: flex;
        padding: 12px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--Black, #2e2e2e);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        background: #fff;
        border: 1px solid #fff;
        &:hover {
          color: var(--Prepaire-Grey, #434343);
        }
      }
      .active-btn {
        border-bottom: 2px solid #72c420;
        font-weight: 700;
      }
      .control-btn:last-child {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        &:focus {
          outline: none;
        }
        &:hover {
          border: 1px solid var(--Black, #2e2e2e);
        }
      }
    }
    .menu-btn {
      display: none;
      background: #fff;
      border: 1px solid #fff;
      padding: 0px;
      &:focus {
        outline: none;
      }
    }
    @media (max-width: 1024px) {
      .menu-btn {
        display: block;
      }
    }
  }
  .expand-menu-container {
    display: flex;
    flex-direction: column;
    padding: 0px 16px 12px 16px;
    gap: 40px;
    .control-btn-group-container {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      .control-btn {
        color: var(--Black, #2e2e2e);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 200% */
      }
      .control-btn:last-child {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        line-height: 24px;
        &:focus {
          outline: none;
        }
      }
    }
    .bottom-contents {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      align-self: stretch;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
      }
      .social-btns-container {
        display: inline-flex;
        align-items: flex-start;
        gap: 8px;
        a {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #fff;
          text-align: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      img {
        width: 114.237px;
        height: 32px;
        flex-shrink: 0;
      }
    }
  }
}
